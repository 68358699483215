<template>
  <div>
    <div class="grid crud-demo">

        <div class="col-12 md:col-12 lg:col-12" v-if='totalCliElab > 0 && escondeAvisoCliElab === false'>

            <div class="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
                <div class="font-bold">🔔 Existem {{ totalCliElab }} clientes em elaboração </div>
                <div class="align-items-center hidden lg:flex">
                    <span class="line-height-3">, favor finalizar a integração! 🔔</span>
                </div>
                <a @click='escondeAvisoCliElab = true' v-ripple class="flex align-items-center no-underline justify-content-center border-circle text-gray-50 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150 p-ripple" style="width:2rem; height: 2rem">
                    <i class="pi pi-times"></i>
                </a>
            </div>

        </div>

      <div class="col-12">
        <div class="card">


            <h6 class="text-400">Administração de Clientes

                <span :class="'ml-3 cliente-badge status-a'"> {{ totalAtivos }} Ativos</span>
                <span :class="'ml-3 cliente-badge status-i'"> {{ totalInativos }} Inativos</span>
                <span :class="'ml-3 cliente-badge status-e'"> {{ totalCliElab }} Elaboração</span>
            </h6>



          <Toast/>
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <Button label="Novo Cliente" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" v-if="userData"/>
              </div>
            </template>

            <template v-slot:end>
              <FileUpload mode="basic" accept=".pdf" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" v-if="userData && userData.tipo === 'admin'"/>
              <Button label="Exportar" icon="pi pi-download" class="p-button-outlined" @click="exportCSV($event)"  v-if="userData && userData.tipo === 'admin'"/>
            </template>
          </Toolbar>

          <DataTable ref="dt" :value="clientes" dataKey="id" :paginator="true" :rows="10"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} clientes" responsiveLayout="scroll">
            <template #header>
              <div class="flex justify-content-between flex-column sm:flex-row">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="pesqClientes" placeholder="Enter para pesquisar..." style="width: 100%" @keydown="pesquisarCliente" />
                  </span>
              </div>
            </template>

            <!--          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
            <Column field="id" header="ID" :sortable="true" filterField="id" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <a @click="editCliente(slotProps.data)" class="cursor-pointer">
                {{slotProps.data.id}}
                </a>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo id"/>
              </template>
            </Column>

              <Column field="codexterno" header="Código" :sortable="true" filterField="codexterno" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
                  <template #body="slotProps">
                      <a @click="editCliente(slotProps.data)" class="cursor-pointer">
                          {{slotProps.data.codexterno}}
                      </a>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
                  </template>
              </Column>


            <Column field="nome" header="Nome" :sortable="true" filterField="nome" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Nome</span>
                {{slotProps.data.nome}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo cliente"/>
              </template>
            </Column>

            <Column field="cidade" header="Cidade" :sortable="true" class="text-left" filterField="cidade" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Cidade</span>
                {{formatCurrency(slotProps.data.cidade)}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela cidade"/>
              </template>
            </Column>
            <Column field="estado" header="Estado" :sortable="true" class="text-right" filterField="estado" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Estado</span>
                {{slotProps.data.estado}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela estado"/>
              </template>
            </Column>
            <Column field="category" header="Categoria" :sortable="true" filterField="categoria" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Category</span>
                {{slotProps.data.category}}
              </template>

              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela categoria"/>
              </template>

            </Column>

            <Column field="status" header="Status" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Status</span>
                <span :class="'cliente-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{ nomeStatus(slotProps.data.status) }}</span>
              </template>
            </Column>

            <Column header="Última Compra" dataType="date" style="min-width:10rem">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>

            </Column>

            <Column field="valor" header="Valor" class="text-right" :sortable="true" filterField="valor" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Valor</span>
                {{formatCurrency(slotProps.data.balance)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>


            <Column header="Ações" class="text-right">
              <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editCliente(slotProps.data)" />
                <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2" @click="visibleTimeline = true" />
              </template>
            </Column>

          </DataTable>

        </div>
      </div>


    </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h6>15 dias</h6>
          <Chart type="doughnut" :data="pieData" :options="pieOptions" style="position:relative; width: 50%"></Chart>
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card">
          <h6>Últimos Meses</h6>
          <Chart type="line" :data="lineData" :options="lineOptions"></Chart>
        </div>
      </div>
    </div>


    <div class="col-12 lg:col-12">
      <Sidebar v-model:visible="visibleTimeline" :baseZIndex="1000" position="right">
        <h3 style="font-weight: normal">Timeline</h3>
        <clientes-list-timeline></clientes-list-timeline>
      </Sidebar>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import ClienteService from '@/service/ClienteService';
import ClientesListTimeline from "@/components_esol/clientes/ClientesListTimeline";


export default {
  components: {
    ClientesListTimeline

  },
  data() {
    return {
      totalCliElab: 0,
      escondeAvisoCliElab: false,
      totalAtivos: 0,
      totalInativos: 0,
      totalElaboracao: 0,
      userData: null,
      pesqClientes: '',
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      clientes: null,

      cliente: {},

      submitted: false,
      statuses: [
        {label: 'QUALIFICADO', value: 'qualificado'},
        {label: 'ANÁLISE', value: 'análise'},
        {label: 'DESQUALIFICADO', value: 'desqualificado'}
      ],

      categorias: [
        {name: "Rural" },
        {name: "Residencial" },
        {name: "Grandes Negócios" },
      ],

      pieData: [],
      pieOptions: [],

      lineData: [],
      lineOptions: [],

    }
  },
  clienteService: null,
  created() {
    this.clienteService = new ClienteService();
    this.initFilters();
  },

  mounted() {
    this.getClientes()
  },

  methods: {

    pesquisarCliente(e){
      console.log('pesquisarCliente')
      console.log(e.keyCode)
      if (e.keyCode === 13){
        if (this.pesqClientes.length < 3){
          this.$toast.add({severity:'warn', summary: 'Texto Pesquisa', detail:'Favor informar no mínimo 3 caracteres para pesquisa', life: 3000});
        }else{
          this.getClientes()
        }
      }
    },

    getClientes() {
      this.loading1 = true
      this.clienteService.getssclienteindex({
        'q': this.pesqClientes
      }).then((data) => {
        console.log('clientesList mounted')
        this.loading1 = false
        this.userData = data[0]
        this.clientes = data[1]
        let labelsPolarChart = []
        this.clientes.forEach((cliente) => {
          cliente.date = new Date(cliente.date)
          if (cliente.estado)
            labelsPolarChart.push(cliente.estado)
        });
        labelsPolarChart = [...new Set(labelsPolarChart)]

        this.loading1 = false

        let valuesPolarData = []
        labelsPolarChart.forEach((label) => {
          if (label != null && label != 'null'){
            // console.log(label)
            let clientesFiltr =  this.clientes.filter(x => x.estado === label)
            //let valorTot = 0
            let contTot = 0
            clientesFiltr.forEach((cliente) => {
              if (cliente.estado)
                contTot += 1
              //console.log(orcto.valortotal)
            })
            valuesPolarData.push(contTot)
            // console.log('totalLabel')
            // console.log(totalLabel)
          }
        })

        let labelsLinesChart = data[2]
        let valuesLines1Chart = data[3]
        let valuesLines2Chart = data[4]

        console.log(data)

        this.totalAtivos = data[5].totalAtivo
        this.totalInativos = data[5].totalInativo
        this.totalCliElab = data[5].elaboracao

        this.pieData = {
          labels: labelsPolarChart,
          datasets: [
            {
              data: valuesPolarData,
              backgroundColor: [
                'rgb(54, 162, 235)',
                'rgb(255, 99, 132)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)'
              ]
            }]
        }
        this.pieOptions =  {
          plugins: {
            legend: {
              labels: {
                color: '#A0A7B5'
              }
            }
          }
        }

        this.lineData = {
          labels: labelsLinesChart,
          datasets: [
            {
              label: 'Análise',
              data: valuesLines1Chart,
              fill: false,
              backgroundColor: 'rgb(255, 205, 86)',
              borderColor: 'rgb(255, 205, 86)',
              tension: .4
            },
            {
              label: 'Qualificado',
              data: valuesLines2Chart,
              fill: false,
              backgroundColor: 'rgb(75, 192, 192)',
              borderColor: 'rgb(75, 192, 192)',
              tension: .4
            }
          ]
        }

        this.lineOptions =  {
          plugins: {
            legend: {
              labels: {
                color: '#A0A7B5'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#A0A7B5'
              },
              grid: {
                color:  'rgba(160, 167, 181, .3)',
              }
            },
            y: {
              ticks: {
                color: '#A0A7B5'
              },
              grid: {
                color:  'rgba(160, 167, 181, .3)',
              }
            },
          }
        }
      })
    },


    nomeStatus(sigla){
      switch (sigla) {
        case 'A':
          return 'Ativo'
        case 'I':
          return 'Inativo'
        case 'EL':
          return 'Elaboração'
        case 'CO':
          return 'Confirmado'
        default:
          return 'Não Definido'
      }
    },

    openNew() {
      this.$router.push({ path: '/clientedetalhe/0' });
    },

    editCliente(cliente) {
      this.$router.push({ path: `/clientedetalhe/${cliente.id}` });
    },

    initFilters() {
      this.filters1 = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'nome': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'cidade': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'estado': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'categoria': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'valor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
      }

    },

    clearFilter1() {
      this.initFilters();
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return '';
    },

    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return '';
    },

  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
